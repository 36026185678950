const Menu = [
    {
      heading: "menu",
      route: "/stipend",
      pages: [
        {
          heading: "Add Field Visit Info ",
          route: "/fieldvisit/add-visit-info",
          fontIcon: "fa-user",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        {
          heading: "Visit list ",
          route: "/fieldvisit/visit-list",
          fontIcon: "fa-user",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
        // {
        //   heading: "Edit Field Visit Info ",
        //   route: "/fieldvisit/edit-visit-info",
        //   fontIcon: "fa-user",
        //   svgIcon: "media/icons/duotune/general/gen019.svg",
        // },
        {
          heading: "Visit Report ",
          route: "/fieldvisit/visit-report",
          fontIcon: "fa-user",
          svgIcon: "media/icons/duotune/general/gen019.svg",
        },
      ],
    },
  ];
  
  export default Menu;
  